import {useEffect, useState, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {fetchDbUsersData} from "../../_store/features/user-db/user-db-actions";
import {auth} from "../../firebase";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/primitives/Avatar";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {CancelCircleFilled, CheckCircleFilled, DownloadList, EmailFilled, ListPlus, Pencil, PhoneFilled} from "../../components/primitives/icons";
import {ROLES as APP_ROLES, exportAdminList, FetchDbUsersParams} from "../../data-access/fetch-db-users";
import {downloadCSV} from "../../../utils/downloadCSV";
import {Link} from "react-router-dom";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../components/blocks/DataTable";
import {dbUser} from "../../_store/features/user-db/user-db-slice";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../components/primitives/ToolTip";
import useCurrentUserRole from "../../components/hooks/useCurrentUserRole";

const searchOptions: {display: string; searchby: FetchDbUsersParams["searchby"]}[] = [
	{display: "Nombre", searchby: "name"},
	{display: "Correo", searchby: "email"},
	{display: "Teléfono", searchby: "phone"},
	{display: "ID de Usuario", searchby: "id"},
];

const rolesOptions: {display: string; value: APP_ROLES | undefined}[] = [
	{display: "Todos", value: undefined},
	{display: "Admin", value: "admin"},
	{display: "Master Editor", value: "master_editor"},
	{display: "Corporate", value: "corporate"},
	{display: "Editor", value: "editor"},
	{display: "Influencer", value: "influencer"},
	{display: "Partner", value: "partner"},
	{display: "Sponsor", value: "sponsor"},
	{display: "Customer Service", value: "customer_service"},
	{display: "Marketing", value: "marketing"},
];

const statusOptions = [
	{display: "Todos", value: undefined},
	{display: "Activos", value: true},
	{display: "Inactivos", value: false},
];

const columnHelper = createColumnHelper<dbUser>();

function UsersList() {
	const currentUserRole = useCurrentUserRole();
	const reduxDbUsers = useAppSelector(state => state.dbuser);
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedRole, setSelectedRole] = useState(currentUserRole === "marketing" ? rolesOptions[5] : rolesOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [searchValue, setSearchValue] = useState("");

	const handleSearch = () => {
		if (pagination.pageSize === 0) return;
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: pagination.pageIndex,
					page_size: pagination.pageSize,
					admin: true,
					searchby: selectedSearchBy.searchby,
					searchvalue: searchValue,
					role: selectedRole.value,
					active: selectedStatus.value,
				}),
			);
		});
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const handleCSVDownload = () => {
		auth.currentUser
			?.getIdToken()
			.then(token => {
				exportAdminList(token, {
					coin: true,
					disabled: true,
					email: true,
					email_verified: true,
					name: true,
					phone: true,
					phone_verified: true,
				})
					.then(res => {
						downloadCSV(res.data.result);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}

		return [
			columnHelper.accessor("photo_url", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => (
					<Avatar>
						<AvatarImage src={info.row.original?.photo_url} alt="User profile" />
						<AvatarFallback>{info.row.original?.email?.slice(0, 2)}</AvatarFallback>
					</Avatar>
				),
				size: AVATAR_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("email", {
				id: "Email",
				header: "Email",
				cell: info => <div className="ml-2 w-full truncate text-left">{info.getValue()}</div>,
				size: Math.floor(tableWidth * 0.25),
			}),
			columnHelper.accessor("phone", {
				id: "No. Teléfono",
				header: "No. Teléfono",
				cell: info => <div className="ml-2 w-full text-left">{info.getValue()}</div>,
				size: Math.floor(tableWidth * 0.15),
			}),
			columnHelper.accessor("email_verified", {
				id: "Estado",
				header: "Estado",
				cell: info => (
					<TooltipProvider>
						<div className="ml-2 flex w-full items-center space-x-4">
							<Tooltip>
								<TooltipTrigger>
									{info.row.original.disabled ? (
										<CancelCircleFilled className="h-6 w-6 text-destructive" />
									) : (
										<CheckCircleFilled className="h-6 w-6 text-success" />
									)}
								</TooltipTrigger>
								<TooltipContent>{info.row.original.disabled ? "Deshabilitado" : "Habilitado"}</TooltipContent>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger>
									<EmailFilled className={info.row.original.email_verified ? "h-6 w-6 text-success" : "h-6 w-6 text-destructive"} />
								</TooltipTrigger>
								<TooltipContent>{info.row.original.email_verified ? "Verificado" : "Sin Verificar"}</TooltipContent>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger>
									<PhoneFilled className={info.row.original.phone_verified ? "h-6 w-6 text-success" : "h-6 w-6 text-destructive"} />
								</TooltipTrigger>
								<TooltipContent>{info.row.original.phone_verified ? "Verificado" : "Sin Verificar"}</TooltipContent>
							</Tooltip>
						</div>
					</TooltipProvider>
				),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild>
							<Link to={"/users/edit/" + info.row.original.id}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [tableRef]);

	const refresh = () => {
		setSelectedSearchBy(searchOptions[0]);
		setSelectedStatus(statusOptions[0]);
		setSelectedRole(rolesOptions[0]);
		setSearchValue("");
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					admin: true,
					searchvalue: "",
					searchby: searchOptions[0].searchby,
					role: rolesOptions[0].value,
					active: statusOptions[0].value,
				}),
			);
		});
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.display === value) ?? statusOptions[0];
		setSelectedStatus(status);
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					admin: true,
					searchvalue: "",
					searchby: searchOptions[0].searchby,
					role: rolesOptions[0].value,
					active: status.value,
				}),
			);
		});
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.searchby === value) ?? searchOptions[0]);
	};

	const handleRoleFilter = (value: string) => {
		const role = rolesOptions.find(el => el.display === value) ?? rolesOptions[0];
		setSelectedRole(role);
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					admin: true,
					searchvalue: "",
					searchby: searchOptions[0].searchby,
					role: role.value,
					active: statusOptions[0].value,
				}),
			);
		});
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<h2 className="text-2xl font-bold tracking-tight">
					{currentUserRole === "marketing" ? "Lista de Influencers" : "Lista de usuarios administrativos"}
				</h2>
				<Button className="gap-2" asChild>
					<Link to="/users/new">
						<ListPlus />
						Nuevo usuario
					</Link>
				</Button>
			</div>
			<div className="flex gap-8 px-6 pt-4 xl:gap-24">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleStatusFilter} value={selectedStatus.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
					<Select onValueChange={handleRoleFilter} value={selectedRole.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Rol:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent className="h-44">
							<SelectGroup className="h-44 scrollbar">
								{rolesOptions.map((rOption, idx) => (
									<SelectItem
										key={idx}
										value={`${rOption.display}`}
										disabled={currentUserRole === "marketing" && rOption.display !== "Influencer"}
									>
										{rOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
				<Button variant="outline" size="sm" className="h-8 whitespace-nowrap font-normal lg:flex" onClick={handleCSVDownload}>
					<DownloadList className="mr-2 h-4 w-4" />
					Exportar CSV
				</Button>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={reduxDbUsers.results}
					pageCount={Math.ceil(reduxDbUsers.totalResults / reduxDbUsers.pageSize)}
					pagination={pagination}
					loading={reduxDbUsers.loading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
				/>
			</div>
		</div>
	);
}

export default UsersList;
