export const MEMBERSHIP_TYPES = [
	{label: "Free", value: 0},
	{label: "Miembro", value: 1},
	{label: "Miembro VIP", value: 2},
	{label: "Upgrade", value: 3},
];

export const CONTENT_TYPES = [
	{label: "Capitulo", value: 0},
	{label: "Preview", value: 1},
	{label: "Contenido", value: 2},
	{label: "Short", value: 3},
	{label: "Stream", value: 4},
	{label: "Live Stream", value: 5},
	{label: "Decide Tu", value: 6},
];

export const PREMIERE_TYPES = [
	{label: "Estreno", value: 0},
	{label: "Estreno en Vivo", value: 1},
];

export const PROVIDERS: Record<string, string> = {
	password: "Password",
	"google.com": "Google",
	"apple.com": "Apple",
	"facebook.com": "Facebook",
	custom: "Custom",
};

export const PUBLICITY_TYPES = [
	{label: "General", value: 0},
	{label: "Sponsor", value: 1},
];

export enum CONTENT {
	CAPITULO = 0,
	PREVIEW = 1,
	CONTENT = 2,
	SHORT = 3,
	STREAM = 4,
	LIVE_STREAM = 5,
	CHOICE_ENDING = 6,
	EXCLUSIVE_ROOM = 7,
}

export enum GROUPING {
	DAILY = 1,
	WEEKLY = 2,
	MONTHLY = 3,
}

export const VERIFICATIONS_TYPES = [
	{
		value: 1,
		label: "Correo verificado",
	},
	{
		value: 2,
		label: "Teléfono verificado",
	},
	{
		value: 3,
		label: "Ambos",
	},
	{
		value: 4,
		label: "Usuarios sin verificar",
	},
];

export const GENDER_TYPES = [
	{
		value: "H",
		label: "Hombre",
	},
	{
		value: "M",
		label: "Mujer",
	},
	{
		value: "O",
		label: "Otro",
	},
];

export const SUBSCRIPTIONS_TYPES = [
	{label: "Mensual", value: 0},
	{label: "Anual", value: 1},
	{label: "Mensual con cupo", value: 2},
	{label: "Anual con cupo", value: 3},
];

export const MAX_DIAMETER = 15;

export const USER_ROLES = ["admin", "master_editor", "editor", "partner", "sponsor", "influencer", "marketing", "corporate", "customer_service"];

export const RENTALS_TYPES = [
	{label: "Renta Barata", value: 0},
	{label: "Renta Normal", value: 1},
	{label: "Renta Cara", value: 2},
];

export const COMMON_APP_SCREENS = [
	{label: "Pantalla de Serie", value: "SeriesScreen"},
	{label: "Pantalla de Contenido", value: "MN_ContentScreen"},
	{label: "Pantalla de los shorts", value: "ShortsScreen"},
	{label: "Pantalla de Populares", value: "AP_PopularScreen"},
	{label: "Pantalla de Perfil", value: "ProfileScreen"},
	{label: "Pantalla de Rentas", value: "RentalScreen"},
	{label: "Pantalla de lista de Lives", value: "MN_LiveListScreen"},
	{label: "Pantalla de Live", value: "MN_LivePlayerScreen"},
	{label: "Pantalla de Estrenos", value: "AP_EstrenosScreen"},
	{label: "Pantalla de Chat", value: "ChatScreen"},
	{label: "Pantalla de Sala Exclusiva", value: "MN_ExclusiveRoomScreen"},
	{label: "Pantalla de Chat de Sala Exclusiva", value: "MN_ExclusiveRoomChatScreen"},
	{label: "Pantalla de Invitación al Plan Amigos", value: "AcceptBuddyPlanInvitationScreen"},
];

// *** The commented audience types is not implemented yet
export const AUDIENCE_TYPES = [
	{value: "subscribers", label: "Suscritos"},
	{value: "non_subscribers", label: "No suscritos"},
	{
		value: "new_registered_non_subscriber_users",
		label: "Usuario nuevo registrado no suscrito (se considera usuario nuevo al que se registró en un periodo de 7 días)",
	},
	{value: "new_registered_subscriber_users", label: "Usuario Nuevo suscrito"},
	{value: "users_in_usa", label: "Usuarios en los EEUU."},
	{value: "users_out_usa", label: "Usuarios fuera de los EEUU."},
	// {value: "last_7_days_visitors", label: "No han entrado hace 7 días"},
	// {value: "last_15_days_visitors", label: "No han entrado hace 15 días"},
	// {value: "last_30_days_visitors", label: "No han entrado hace 30 días"},
	// {value: "exclusive_room_members", label: "Pertenecen a sala exclusiva"},
	// {value: "free_content_viewers", label: "No están suscrito y ya vieron todo lo gratis"},
	// {value: "all_content_viewers", label: "Han Visto todo"},
	// {value: "series_completed_viewers", label: "Han visto una serie completa"},
	// {value: "engaged_with_x_series_viewers", label: "Enagagement con X serie (es un usuario que ha visto al menos un capítulo o trailer de X serie)"},
	// {value: "non_engaged_with_x_series_viewers", label: "No Engagement con X serie (usuario que no ha visto nada de X serie)"},
	// {value: "unverified_users", label: "Usuario no verificado"},
	// {value: "phoneless_users", label: "Usuario sin teléfono"},
	// {value: "notification_disabled_users", label: "Usuario sin activar notificaciones"},
	// {value: "exclusive_x_room_users", label: "Usuario de X sala esclusiva"},
	// {value: "coin_buyers", label: "Usuario que ha comprado Coins"},
	// {value: "non_coin_buyers", label: "Usuario que nunca ha comprado Coins"},
	// {value: "series_content_commenters", label: "Usuario que ha comentado en algún contenido(capítulo o trailer) de X serie"},
	// {value: "series_x_followers", label: "Usuario que le dio Follow a X serie"},
	// {value: "series_x_reminder_users", label: "Usuario que le dio Avisame a X serie"},
];

export const SLOTS_STATES = [
	{value: "active", label: "Activo"},
	{value: "available", label: "Disponible"},
	{value: "pending", label: "Pendiente"},
];
