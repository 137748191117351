import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {downloadCSV} from "../../../utils/downloadCSV";
import {fetchDbUsersData} from "../../_store/features/user-db/user-db-actions";
import {dbUser} from "../../_store/features/user-db/user-db-slice";
import {useAppDispatch, useAppSelector} from "../../_store/hooks";
import {DataTable} from "../../components/blocks/DataTable";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/primitives/Avatar";
import {Button} from "../../components/primitives/Button";
import {Input} from "../../components/primitives/Input";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../components/primitives/Select";
import ToolTip from "../../components/primitives/ToolTip-toDeprecate";
import {CancelCircleFilled, CheckCircleFilled, DownloadList, EmailFilled, ListPlus, Pencil, PhoneFilled} from "../../components/primitives/icons";
import {Refresh} from "../../components/primitives/icons/Refresh";
import {PROVIDERS} from "../../constants";
import {exportUserList, FetchDbUsersParams} from "../../data-access/fetch-db-users";
import {auth} from "../../firebase";
import {DataTableColumnHeader} from "../../components/primitives/DataTable";
import useCurrentUserRole from "../../components/hooks/useCurrentUserRole";
import EmailSendInformation from "./subcomponents/EmailSendInformation";

const searchOptions: {display: string; searchby: FetchDbUsersParams["searchby"]}[] = [
	{display: "Por Nombre", searchby: "name"},
	{display: "Por Correo", searchby: "email"},
	{display: "Por Teléfono", searchby: "phone"},
	{display: "Por ID de Usuario", searchby: "id"},
	{display: "Por ID de Dispositivo", searchby: "device_id"},
];

const statusOptions = [
	{display: "Todos", value: undefined},
	{display: "Activos", value: true},
	{display: "Inactivos", value: false},
];

const associateOptions = [
	{display: "Todos", value: undefined},
	{display: "Sí", value: true},
	{display: "No", value: false},
];

const columnHelper = createColumnHelper<dbUser>();

function UsersList() {
	const userRole = useCurrentUserRole();
	const reduxDbUsers = useAppSelector(state => state.dbuser);
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [selectedSearchBy, setSelectedSearchBy] = useState(searchOptions[0]);
	const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
	const [associatedStatus, setAssociatedStatus] = useState(associateOptions[0]);
	const [searchValue, setSearchValue] = useState("");
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const isAuthorized = useMemo(() => {
		switch (userRole) {
			case "admin":
			case "master_editor":
			case "corporate":
			case "customer_service":
				return true;
			default:
				return false;
		}
	}, [userRole]);

	const handleSearch = () => {
		if (pagination.pageSize === 0) return;
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: pagination.pageIndex,
					page_size: pagination.pageSize,
					admin: false,
					searchby: selectedSearchBy.searchby,
					searchvalue: searchValue,
					role: undefined,
					active: selectedStatus.value,
					associate: associatedStatus.value,
				}),
			);
		});
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;
		const AVATAR_COL_WIDTH = 60;

		if (tableWidth > 0) {
			tableWidth = tableWidth - AVATAR_COL_WIDTH;
		}

		return [
			columnHelper.accessor("photo_url", {
				id: "Avatar",
				header: ({column}) => <DataTableColumnHeader title="Avatar" column={column} />,
				cell: info => {
					return (
						<Avatar>
							<AvatarImage src={info.row.original.photo_url} alt="User profile" />
							<AvatarFallback>{info.row.original?.email?.slice(0, 2)}</AvatarFallback>
						</Avatar>
					);
				},
				size: AVATAR_COL_WIDTH,
				enableResizing: false,
				enableColumnFilter: false,
				enableSorting: false,
			}),
			columnHelper.accessor("name", {
				id: "Nombre  / UUID",
				header: "Nombre / UUID",
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left text-neutral-500">{info.row.original.id}</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.3),
			}),
			columnHelper.accessor("email", {
				id: "Email",
				header: "Email",
				cell: info => <div className="ml-2 w-full text-left">{info.getValue()}</div>,
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.accessor("phone", {
				id: "No. Teléfono",
				header: "No. Teléfono",
				cell: info => <div className="ml-2 w-full text-left">{info.getValue()}</div>,
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("provider", {
				id: "Proveedor",
				header: "Proveedor",
				cell: info => {
					const providerList = info.getValue().split(",");
					return (
						<span className="ml-2 flex w-full text-left">
							{providerList.reduce((pv, cv, indx) => {
								return `${pv}${PROVIDERS[cv]}${indx + 1 < providerList.length ? ", " : ""}`;
							}, "")}
						</span>
					);
				},
				size: Math.floor(tableWidth * 0.1),
			}),
			columnHelper.accessor("email_verified", {
				id: "Estado",
				header: "Estado",
				cell: info => (
					<div className="flex w-full items-center">
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.disabled ? " text-red-700" : " text-green-700")
							}
						>
							<ToolTip
								text={info.cell.row.original.disabled ? "Deshabilitado" : "Activo"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								{info.cell.row.original.disabled ? <CancelCircleFilled /> : <CheckCircleFilled />}
							</ToolTip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.email_verified ? " text-green-700" : " text-red-700")
							}
						>
							<ToolTip
								text={info.cell.row.original.email_verified ? "Verificado" : "Sin Verificar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<EmailFilled />
							</ToolTip>
						</span>
						<span
							className={
								"relative inline-block px-3 py-1 font-semibold leading-tight" +
								(info.cell.row.original.phone_verified ? " text-green-700" : " text-red-700")
							}
						>
							<ToolTip
								text={info.cell.row.original.phone_verified ? "Verificado" : "Sin Verificar"}
								placement={info.row.index === 0 ? "bottom" : "top"}
							>
								<PhoneFilled />
							</ToolTip>
						</span>
					</div>
				),
				size: Math.floor(tableWidth * 0.2),
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="ml-2 flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} asChild disabled={!isAuthorized}>
							<Link to={isAuthorized ? "/users/edit/" + info.row.original.id : ""}>
								<Pencil className="h-4 w-4" />
							</Link>
						</Button>
					</div>
				),
				size: Math.floor(tableWidth * 0.1),
				enableResizing: false,
			}),
		];
	}, [tableRef, isAuthorized]);

	const handleCSVDownload = () => {
		auth.currentUser
			?.getIdToken()
			.then(token => {
				exportUserList(token, {
					coin: true,
					disabled: true,
					email: true,
					email_verified: true,
					name: true,
					phone: true,
					phone_verified: true,
				})
					.then(res => {
						downloadCSV(res.data.result);
					})
					.catch(err => {
						console.log(err);
					});
			})
			.catch(err => {
				console.log(err);
			});
	};

	const refresh = () => {
		setSelectedStatus(statusOptions[0]);
		setSelectedSearchBy(searchOptions[0]);
		setAssociatedStatus(associateOptions[0]);
		setPagination(prev => ({...prev, pageIndex: 0}));
		setSearchValue("");
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					admin: false,
					searchvalue: "",
					searchby: searchOptions[0].searchby,
					active: statusOptions[0].value,
					associate: associateOptions[0].value,
				}),
			);
		});
	};

	const handleStatusFilter = (value: string) => {
		const status = statusOptions.find(el => el.display === value) ?? statusOptions[0];
		setSelectedStatus(status);
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					admin: false,
					searchvalue: "",
					searchby: searchOptions[0].searchby,
					active: status.value,
					associate: associateOptions[0].value,
				}),
			);
		});
	};

	const handleAssociatedFilter = (value: string) => {
		const status = associateOptions.find(el => el.display === value) ?? associateOptions[0];
		setAssociatedStatus(status);
		auth.currentUser?.getIdToken().then(idToken => {
			dispatch(
				fetchDbUsersData({
					idToken,
					page: 0,
					page_size: pagination.pageSize,
					admin: false,
					searchvalue: "",
					searchby: searchOptions[0].searchby,
					active: statusOptions[0].value,
					associate: status.value,
				}),
			);
		});
	};

	const handleSearchByFilter = (value: string) => {
		setSelectedSearchBy(searchOptions.find(el => el.searchby === value) ?? searchOptions[0]);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="mx-6 flex items-center justify-between border-b border-border pb-4 pt-6">
				<div className="flex items-center gap-5">
					<h2 className="text-2xl font-bold tracking-tight">Lista de Usuarios</h2>
					<div className="mx-4 flex flex-row items-start">
						<span className="text-ellipsis text-center text-2xl font-bold">Total: </span>
						<span className="ml-2 text-ellipsis text-center text-2xl font-bold">{reduxDbUsers.totalResults}</span>
					</div>
				</div>
				<div className="flex gap-2">
					<EmailSendInformation />
					{userRole !== "corporate" && (
						<Button className="gap-2" asChild>
							<Link to="/users/new">
								<ListPlus />
								Nuevo usuario
							</Link>
						</Button>
					)}
				</div>
			</div>
			<div className="flex gap-8 px-6 pt-4 xl:gap-24">
				<div className="flex w-full gap-2">
					<Button className="h-8" size={"sm"} variant={"outline"} onClick={() => refresh()}>
						<Refresh className="h-4 w-4" />
					</Button>
					<div className="flex w-full">
						<Input
							className="h-8 rounded-r-none"
							type="text"
							onChange={e => setSearchValue(e.target.value)}
							value={searchValue}
							placeholder="Buscar..."
						/>
						<Select onValueChange={handleSearchByFilter} value={selectedSearchBy.searchby}>
							<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap rounded-none">
								<span>Buscar Por:</span>
								<SelectValue placeholder="" />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									{searchOptions.map((sOption, idx) => (
										<SelectItem key={idx} value={`${sOption.searchby}`}>
											{sOption.display}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
						<Button className="h-8 rounded-l-none" size={"sm"} onClick={handleSearch}>
							Buscar
						</Button>
					</div>

					<Select onValueChange={handleStatusFilter} value={selectedStatus.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Mostrar:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{statusOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>

					<Select onValueChange={handleAssociatedFilter} value={associatedStatus.display}>
						<SelectTrigger className="h-8 w-fit gap-1 whitespace-nowrap">
							<span>Asociados:</span>
							<SelectValue placeholder="" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								{associateOptions.map((sOption, idx) => (
									<SelectItem key={idx} value={`${sOption.display}`}>
										{sOption.display}
									</SelectItem>
								))}
							</SelectGroup>
						</SelectContent>
					</Select>
				</div>
				<Button variant="outline" size="sm" className="h-8 whitespace-nowrap font-normal lg:flex" onClick={handleCSVDownload}>
					<DownloadList className="mr-2 h-4 w-4" />
					Exportar CSV
				</Button>
			</div>
			<div className="flex h-full flex-col p-6 pt-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={reduxDbUsers.results}
					pageCount={Math.ceil(reduxDbUsers.totalResults / reduxDbUsers.pageSize)}
					pagination={pagination}
					loading={reduxDbUsers.loading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
				/>
			</div>
		</div>
	);
}

export default UsersList;
