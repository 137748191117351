import {PaginationState, createColumnHelper} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {DataTableColumnHeader} from "../../../../components/primitives/DataTable";
import {ArrowUpTray, Close} from "../../../../components/primitives/icons";
import {Button} from "../../../../components/primitives/Button";
import {Input} from "../../../../components/primitives/Input";
import {DataTable} from "../../../../components/blocks/DataTable";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../../../components/primitives/Select";
import {marketingActions} from "../../../../_store/features/marketing/marketing-slice";
import {CancelledSubscriptionUser} from "../../../../data-access/marketing/marketing";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import {subYears} from "date-fns";
import toast from "react-hot-toast";

const columnHelper = createColumnHelper<CancelledSubscriptionUser>();
const selectOptions = [{name: "Suscripciones Canceladas", value: "cancelled"}];

function ContactFilter() {
	const usersData = useAppSelector(state => state.marketing);
	const dispatch = useAppDispatch();
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 0,
	});
	const [filterValue, setFilterValue] = useState(selectOptions[0]);
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);
	const [error, setError] = useState("");
	const [tags, setTags] = useState<string[]>([]);
	const [dates, setDates] = useState<{from: Date; to: Date}>({
		from: subYears(new Date(), 1),
		to: new Date(),
	});
	const [tag, setTag] = useState("");

	const handleSearch = () => {
		if (pagination.pageSize === 0) return;
		dispatch(
			marketingActions.listCancelledSubscriptionUsers({
				page: pagination.pageIndex,
				page_size: pagination.pageSize,
				initial_termination_date: new Date(dates.from).toISOString(),
				final_termination_date: new Date(dates.to).toISOString(),
			}),
		);
	};

	const handleProcessList = async () => {
		if (tags.length === 0) return;
		dispatch(
			marketingActions.processOdooContactTagsList({
				page_size: 99999,
				initial_termination_date: new Date(dates.from).toISOString(),
				final_termination_date: new Date(dates.to).toISOString(),
				tags,
			}),
		).then(res => {
			if (res.meta.requestStatus === "fulfilled") {
				toast.success("Etiquetas añadidas al listado con éxito");
			} else {
				toast.error("Error procesando la lista");
			}
			setTags([]);
		});
	};

	useEffect(() => {
		if (!pagination.pageSize) return;
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	const columns = useMemo(() => {
		let tableWidth = tableRef?.getBoundingClientRect().width ?? 0;

		return [
			columnHelper.accessor("userid", {
				id: "Correo del Usuario/User ID",
				header: ({column}) => <DataTableColumnHeader title="Correo del Usuario/User ID" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-left font-bold">{info.row.original.email}</span>
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.userid}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.25),
				enableSorting: false,
			}),
			columnHelper.accessor("product_name", {
				id: "Tipo de Subscripción",
				header: ({column}) => <DataTableColumnHeader title="Tipo de Subscripción" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.product_name}</span>
					</div>
				),
				size: Math.round(tableWidth * 0.25),
				enableSorting: false,
			}),
			columnHelper.accessor("subscription_date", {
				id: "Fecha de Inicio",
				header: ({column}) => <DataTableColumnHeader title="Fecha de Inicio" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">
							{new Date(info.row.original.subscription_date).toLocaleDateString()}
						</span>
					</div>
				),
				size: Math.round(tableWidth * 0.2),
				enableSorting: false,
			}),
			columnHelper.accessor("termination_date", {
				id: "Fecha de Terminación",
				header: ({column}) => <DataTableColumnHeader title="Fecha de Terminación" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">
							{new Date(info.row.original.termination_date).toLocaleDateString()}
						</span>
					</div>
				),
				size: Math.round(tableWidth * 0.2),
				enableSorting: false,
			}),
			columnHelper.accessor("termination_date", {
				id: "Duración",
				header: ({column}) => <DataTableColumnHeader title="Duración" column={column} />,
				cell: info => {
					const initialDate = new Date(info.row.original.subscription_date);
					const finalDate = new Date(info.row.original.termination_date);
					const duration = Math.round((finalDate.getTime() - initialDate.getTime()) / (1000 * 60 * 60 * 24 * 30));
					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{duration} meses</span>
						</div>
					);
				},
				size: Math.round(tableWidth * 0.1 - 6),
				enableSorting: false,
			}),
		];
	}, [tableRef]);

	const handleAddTag = () => {
		if (!tag) return;
		setTags([...tags, tag]);
		setTag("");
		setError("");
	};

	const handleDateRangeChange = (start: number, end: number) => {
		setDates({from: new Date(start * 1000), to: new Date(end * 1000)});
		if (pagination.pageIndex !== 0) return setPagination({...pagination, pageIndex: 0});
		dispatch(
			marketingActions.listCancelledSubscriptionUsers({
				page: 0,
				page_size: pagination.pageSize,
				initial_termination_date: new Date(start * 1000).toISOString(),
				final_termination_date: new Date(end * 1000).toISOString(),
			}),
		);
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="flex gap-8 pt-4 xl:gap-24">
				<div className="flex w-full justify-between">
					<div className="flex">
						<div className="mr-4 w-fit min-w-64">
							<Select
								value={filterValue.value}
								onValueChange={value => setFilterValue(selectOptions.find(el => el.value === value) ?? selectOptions[0])}
							>
								<SelectTrigger>
									<SelectValue placeholder="" />
								</SelectTrigger>
								<SelectContent>
									{selectOptions.map((option, index) => (
										<SelectItem key={index} value={option.value}>
											{option.name}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
						<DatePickerWithRange
							onDateRangeChange={handleDateRangeChange}
							className="mr-4"
							disabled={{before: subYears(new Date(), 1), after: new Date()}}
							date={dates}
						/>
						<div className="flex">
							<Input
								className="m-auto h-8 w-48 rounded-r-none"
								placeholder="Insertar Etiqueta"
								value={tag}
								onChange={e => {
									if (error) setError("");
									if (tag.length >= 20) {
										setTag(e.target.value.slice(0, 20));
										return setError("No exeder de 20 caracteres");
									}
									setTag(e.target.value);
								}}
								onKeyDown={e => {
									if (e.key === "Enter") handleAddTag();
								}}
							/>
							<Button className="m-auto h-8 rounded-l-none" size={"sm"} onClick={handleAddTag}>
								Añadir
							</Button>
							<div className="m-auto ml-4 flex h-8 gap-4">
								{tags.map((tag, index) => (
									<div key={index} className="relative flex items-center gap-2 rounded-sm bg-slate-500 px-2 py-1 text-white">
										{tag}
										<button
											onClick={() => setTags(tags.filter((_, i) => i !== index))}
											className="absolute -right-2 -top-2 rounded-full border bg-black"
										>
											<Close className="h-4 w-4" />
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
					<Button onClick={handleProcessList} disabled={usersData.loading || tags.length === 0}>
						<ArrowUpTray className="mr-4 h-4 w-4" />
						Procesar Listado
					</Button>
				</div>
			</div>
			<div className="flex h-full flex-col py-4">
				<DataTable
					ref={ref => setTableRef(ref)}
					columns={columns}
					dataset={usersData.results as CancelledSubscriptionUser[]}
					pageCount={Math.ceil(usersData.totalResults / usersData.pageSize)}
					pagination={pagination}
					loading={usersData.loading}
					onPaginationChange={setPagination}
					withDynamicPageSize
					rowHeight={57}
					showPagination={false}
					totalItems={usersData.totalResults}
				/>
			</div>
		</div>
	);
}

export default ContactFilter;
