import {useCallback, useEffect, useMemo, useState} from "react";
import Select, {components, ControlProps, OptionProps, SingleValue} from "react-select";
import {fetchDbInternalUsers} from "../../../data-access/fetch-db-users";
import {auth} from "../../../firebase";
import {dbUser} from "../../../_store/features/user-db/user-db-slice";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../components/primitives/ToolTip";
import {QuestionMarkCircle} from "../../../components/primitives/icons";
import {Avatar, AvatarFallback, AvatarImage} from "../../../components/primitives/Avatar";

interface UserSelectProps {
	onChange: (value: string | undefined) => void;
	isDisabled: boolean;
	required?: boolean;
}

interface UserOption {
	label: string;
	value: string;
	avatar?: string;
}

const Control = ({children, ...props}: ControlProps<UserOption, false>) => {
	return (
		<components.Control {...props}>
			{props.hasValue && (
				<div className="m-1">
					<Avatar>
						<AvatarImage src={props.getValue()[0].avatar} alt="User profile" />
						<AvatarFallback>{props.getValue()[0].label.slice(0, 2)}</AvatarFallback>
					</Avatar>
				</div>
			)}
			{children}
		</components.Control>
	);
};

const Option = ({children, ...props}: OptionProps<UserOption>) => {
	return (
		<components.Option {...props}>
			<div className="items-centers flex gap-4">
				<Avatar>
					<AvatarImage src={props.data?.avatar} alt="User profile" />
					<AvatarFallback>{props.data?.label?.slice(0, 2) ?? "UN"}</AvatarFallback>
				</Avatar>
				{children}
			</div>
		</components.Option>
	);
};

const UserSelect = ({onChange, isDisabled, required = false}: UserSelectProps) => {
	const [users, setUsers] = useState<dbUser[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const usersOptions: UserOption[] = useMemo(() => {
		return users.map(user => ({label: user.name, value: user.id, avatar: user.photo_url}));
	}, [users]);

	const fetchUsers = useCallback(async (query: string) => {
		setIsLoading(true);
		try {
			const idToken = await auth.currentUser?.getIdToken();
			if (!idToken) {
				throw new Error("No token available");
			}
			const response = await fetchDbInternalUsers({idToken, page: 0, page_size: 10000, searchvalue: query});
			setUsers(response.data.results);
		} catch (error) {
			console.error("Error fetching users:", error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchUsers("");
	}, [fetchUsers]);

	const handleSelectType = (newValue: SingleValue<UserOption>) => {
		if (!newValue?.value) return;
		onChange(newValue.value);
	};

	const label = required ? "Seleccione un usuario como autor del comentario" : "Seleccione un usuario como autor del comentario (opcional)";
	const placeholder = required ? "Seleccione un usuario" : "Seleccione un usuario (opcional)";

	return (
		<div className="">
			<div className="mb-2 flex items-center space-x-4">
				<label className="block text-sm font-medium text-gray-600">{label}</label>
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<div>
								<QuestionMarkCircle className="h-4 w-4" />
							</div>
						</TooltipTrigger>
						<TooltipContent>
							<p className="mr-4 w-96">
								Para agregar un usuario interno, dirígete a la sección de <b>Listar Usuarios Internos</b>.
							</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>

			<Select
				className="basic-single mb-4 text-sm"
				classNamePrefix="select"
				defaultValue={usersOptions[0]}
				isLoading={isLoading}
				isClearable={true}
				isSearchable={true}
				placeholder={placeholder}
				name="userid"
				options={usersOptions}
				components={{Control, Option}}
				isDisabled={isDisabled}
				onChange={handleSelectType}
			/>
		</div>
	);
};

export default UserSelect;
