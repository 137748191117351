import {Button} from "../../../components/primitives/Button";
import {Sheet, SheetClose, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../components/primitives/Sheet";
import {Textarea} from "../../../components/primitives/Textarea";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {CustomerServiceCommentInitialBody, CustomerServiceCommentInitialBodySchema} from "../../../data-access/comments/comments";
import {zodResolver} from "@hookform/resolvers/zod";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import {commentsActions} from "../../../_store/features/comments/comments-slice";
import {toast} from "react-hot-toast";
import UserSelect from "./UserSelect";
import Select, {SelectInstance} from "react-select";
import {useEffect, useMemo, useRef, useState} from "react";
import {CONTENT} from "../../../constants";
import {shortsActions, shortsActiveSelector} from "../../../_store/features/shorts/shorts-slice";
import {serieActiveContentSelector} from "../../../_store/features/serie-content/serie-content-slice";

interface CreateSheetProps extends SheetProps {}

interface ContentOption {
	label: string;
	value: string;
	type: number;
}

interface ContentTypeOption {
	label: string;
	value: number;
}

const CONTENT_TYPES = [
	{label: "Capitulo", value: 0},
	{label: "Preview", value: 1},
	{label: "Contenido", value: 2},
	{label: "Short", value: 3},
	{label: "Stream", value: 4},
	{label: "Live Stream", value: 5},
	{label: "Decide Tu", value: 6},
];

export default function CreateSheet(props: CreateSheetProps) {
	const {...rest} = props;
	const dispatch = useAppDispatch();
	const {
		register,
		formState: {errors},
		handleSubmit,
		reset,
		control,
	} = useForm<CustomerServiceCommentInitialBody>({
		resolver: zodResolver(CustomerServiceCommentInitialBodySchema),
		defaultValues: {contentid: "", content: {type: 0, payload: ""}},
	});
	const contentRef = useRef<SelectInstance<ContentOption | null>>(null);

	const [contentType, setContentType] = useState<CONTENT>(CONTENT.CAPITULO);

	const {results: contents, loading} = useAppSelector(serieActiveContentSelector);
	const {results: shorts, loading: loadingShorts} = useAppSelector(shortsActiveSelector);

	const contentOptions: ContentOption[] = useMemo(() => {
		if (contentType === CONTENT.SHORT) return shorts.map(short => ({label: short.title, value: short.id, type: CONTENT.SHORT}));
		return contents
			.filter(c => c.content_type === contentType)
			.map(content => ({label: content.title, value: content.id, type: content?.content_type ?? CONTENT.CAPITULO}));
	}, [contentType, contents, shorts]);

	const contentTypeOptions: ContentTypeOption[] = useMemo(() => {
		return CONTENT_TYPES;
	}, []);

	useEffect(() => {
		dispatch(shortsActions.getShortsList({params: {page: 0, page_size: 200} as any}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInsertReply: SubmitHandler<CustomerServiceCommentInitialBody> = async data => {
		const res = await dispatch(commentsActions.customerServiceCommentInitial(data));
		if (res.meta.requestStatus === "fulfilled") {
			toast.success("Comentario insertado correctamente.", {position: "bottom-left"});
			reset();
		}
		if (res.meta.requestStatus === "rejected") {
			toast.error("Error al insertar el comentario.", {position: "bottom-left"});
		}
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Crear comentario</SheetTitle>
					<SheetDescription>Crea un comentario como un usuario interno en un contenido.</SheetDescription>
				</SheetHeader>
				<form onSubmit={handleSubmit(handleInsertReply)}>
					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Tipo de contenido:</label>
						<Select
							className="basic-single mb-4 text-sm"
							classNamePrefix="select"
							isClearable={false}
							isSearchable={false}
							placeholder="Tipo de contenido"
							name="userid"
							options={contentTypeOptions}
							defaultValue={contentTypeOptions[0]}
							backspaceRemovesValue={false}
							onChange={selected => {
								if (typeof selected === "object" && selected?.value !== undefined && selected.value >= 0) {
									setContentType(selected.value);
									contentRef.current?.clearValue();
								}
							}}
						/>
					</div>

					<div>
						<label className="mb-2 block text-sm font-medium text-gray-600">Contenido:</label>
						<Controller
							name="contentid"
							control={control}
							rules={{required: true}}
							render={({field}) => (
								<Select
									ref={contentRef}
									className="basic-single mb-4 text-sm"
									classNamePrefix="select"
									isLoading={loading || loadingShorts}
									isClearable={true}
									isSearchable={true}
									placeholder="Contenido"
									name="userid"
									options={contentOptions}
									isDisabled={loading}
									onChange={value => field.onChange(value?.value ?? "")}
								/>
							)}
						/>
					</div>

					<Controller
						name="authorid"
						control={control}
						rules={{required: true}}
						render={({field}) => <UserSelect required isDisabled={false} onChange={userid => field.onChange(userid)} />}
					/>
					<div>
						<Textarea placeholder="Insertar respuesta a comentario..." rows={5} {...register("content.payload")} />
						{errors?.content?.payload?.message && (
							<span className="text-sm font-medium text-destructive">{errors?.content?.payload?.message}</span>
						)}
					</div>
					<SheetClose asChild>
						<Button type="submit" className="mt-4">
							Enviar
						</Button>
					</SheetClose>
				</form>
			</SheetContent>
		</Sheet>
	);
}
