import {onAuthStateChanged} from "firebase/auth";
import {useEffect} from "react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {appStatusActions} from "./_store/features/app-status/app-status-slice";
import {userAuthActions} from "./_store/features/user-auth/user-auth-slice";
import {useAppDispatch} from "./_store/hooks";
import {auth} from "./firebase";
import {NotFoundApp} from "./pages/404";
import AdvertisersEdit from "./pages/advertisements/edit";
import AdvertisersList from "./pages/advertisements/List";
import AdvertisersSponsorLists from "./pages/advertisements/ListSponsor";
import AdvertisersNew from "./pages/advertisements/new";
import CommentsDeleted from "./pages/comments/Deleted";
import CommentsList from "./pages/comments/List";
import CommentsReported from "./pages/comments/Reported";
import {CastList} from "./pages/crew/cast";
import {StaffList} from "./pages/crew/staff";
import Contacts from "./pages/customer-service-pages/Contacts";
import Dashboard from "./pages/dashboard";
import {
	ContentStatistics as InfluencerContentStatistics,
	SerieStatistics as InfluencerSerieStatistics,
	SeriesContentInfluencer,
	SeriesInfluencer,
} from "./pages/influencer-pages";
import Layout from "./pages/layout";
import ProtectedRoute from "./pages/layout/ProtectedRoute";
import MultimediaList from "./pages/multimedia/List";
import VideoList from "./pages/multimedia/VideoList";
import VideoPublicityList from "./pages/multimedia/VideoPublicityList";
import {ContentStatistics, SeriesContentPartner, SeriesPartner, SerieStatistics, PartnerReports} from "./pages/partner-pages";
import {CoinProductsEdit, CoinProductsList, CoinProductsNew, SerieProductsEdit, SerieProductsList, SerieProductsNew} from "./pages/products";
import Profile from "./pages/profile";
import RecoveryPassword from "./pages/recovery-password";
import ResetPassword from "./pages/reset-password";
import SeriesEdit from "./pages/series/Edit";
import {SerieContentNew} from "./pages/series/Edit/new-content";
import SeriesList from "./pages/series/List";
import SeriesNew from "./pages/series/New";
import SignIn from "./pages/signin";
import StatisticsSeries from "./pages/statistics/Series";
import StatisticsSubscriptions from "./pages/statistics/Subscriptions";
import {StickersEdit, SeriesStickersList, StickersNew} from "./pages/stickers";
import UsersAdmin from "./pages/users/Admin";
import UserEdit from "./pages/users/edit";
import UsersList from "./pages/users/List";
import UsersNew from "./pages/users/new";
import GlobalNotifications from "./pages/users/Notification";
import ListVersions from "./pages/versions/List";
import CastNew from "./pages/crew/cast/new";
import CastEdit from "./pages/crew/cast/edit";
import StaffNew from "./pages/crew/staff/new";
import StaffEdit from "./pages/crew/staff/edit";
import {ProfessionsList} from "./pages/crew/professions";
import ProfessionNew from "./pages/crew/professions/new";
import ProfessionEdit from "./pages/crew/professions/edit";
import VideoShortList from "./pages/multimedia/VideoShortList";
import ShortsList from "./pages/shorts/List";
import ShortsNew from "./pages/shorts/New";
import ShortEdit from "./pages/shorts/Edit";
import {GlobalStickersList} from "./pages/stickers/GlobalStickersList";
import LiveList from "./pages/lives/List";
import LiveEdit from "./pages/lives/Edit";
import {MarketingDashboard} from "./pages/dashboard/role-based/marketing";
import InfluencerList from "./pages/corporate-pages/influencer/influencer-list";
import InfluencerDetail from "./pages/corporate-pages/influencer/influencer-detail";
import PurchasesDetail from "./pages/corporate-pages/purchases";
import LivePremiere from "./pages/live-premiere";
import SponsorList from "./pages/corporate-pages/sponsor/sponsor-list";
import SponsorDetail from "./pages/corporate-pages/sponsor/sponsor-detail";
import PartnerList from "./pages/corporate-pages/partner/partner-list";
import PartnerDetail from "./pages/corporate-pages/partner/partner-detail";
import RankedSeries from "./pages/corporate-pages/RankedSeries/RankedSeries";
import RankedContents from "./pages/corporate-pages/RankedContents/RankedContents";
import SeriesDetail from "./pages/series/Detail/SeriesDetail";
import ContentDetail from "./pages/series/ContentDetail/ContentDetail";
import UsersInfoStatistics from "./pages/corporate-pages/users/UsersInfoStatistics";
import MarketingNotifications from "./pages/corporate-pages/marketing-notifications/MarketingNotifications";
import VinculatedList from "./pages/users/Vinculated";
import BannersPublicityRangeList from "./pages/advertisements/range-configuration/List";
import VisibilityEdit from "./pages/advertisements/range-configuration/Edit";
import NewVisibilityConfig from "./pages/advertisements/range-configuration/New";
import AddVisibilityList from "./pages/advertisements/visibility";
import UserChat from "./pages/customer-service-pages/user-chat";
import {RentalList} from "./pages/products/RentalsList";
import SerieRentalEdit from "./pages/products/SerieRentalEdit";
import VideoExclusiveRoomList from "./pages/multimedia/VideoExclusiveRoomList";
import Expenses from "./pages/corporate-pages/expenses/Expenses";
import Performance from "./pages/corporate-pages/performance/Performance";
import CustomMarketingNotifications from "./pages/marketing-pages/custom-notifications";
import ProgrammedTasksList from "./pages/settings/programmed-tasks";
import {ProductsPartner} from "./pages/partner-pages/products";
import {ProductStatistics} from "./pages/partner-pages/products/statistics";
import InternalCustomerServiceList from "./pages/users/InternalCustomerServiceList";
import UserInteralEdit from "./pages/users/edit/internals";
import UsersInternalNew from "./pages/users/new/internal";
import OdooManagment from "./pages/users/odoo";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route path="*" element={<NotFoundApp />} />
			<Route path="/sign-in" element={<SignIn />} />
			<Route path="/recovery-password" element={<RecoveryPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			<Route element={<Layout />}>
				<Route index element={<Dashboard />} />
				<Route path="profile/*">
					<Route index element={<Profile />} />
				</Route>
				{/* Admin Routes */}
				<Route element={<ProtectedRoute requiredRole={["admin", "master_editor", "customer_service", "marketing"]} />}>
					<Route path="users/vinculated" element={<VinculatedList />} />
					<Route path="live-premiere/*">
						<Route path=":id" element={<LivePremiere />} />
					</Route>
					<Route path="series/*">
						<Route path="list" element={<SeriesList />} />
						<Route path="new" element={<SeriesNew />} />
						<Route path="edit/:id">
							<Route index element={<SeriesEdit />} />
							<Route path="new-content" element={<SerieContentNew />} />
						</Route>
					</Route>
					<Route path="crew/*">
						<Route path="cast" element={<CastList />} />
						<Route path="cast/new" element={<CastNew />} />
						<Route path="cast/edit/:id" element={<CastEdit />} />
						<Route path="staff" element={<StaffList />} />
						<Route path="staff/new" element={<StaffNew />} />
						<Route path="staff/edit/:id" element={<StaffEdit />} />
						<Route path="professions" element={<ProfessionsList />} />
						<Route path="professions/new" element={<ProfessionNew />} />
						<Route path="professions/edit/:id" element={<ProfessionEdit />} />
					</Route>
					<Route path="products/*">
						<Route path="coin-list" element={<CoinProductsList />} />
						<Route path="coin-new" element={<CoinProductsNew />} />
						<Route path="coin-edit/:id" element={<CoinProductsEdit />} />
						<Route path="serie-list" element={<SerieProductsList />} />
						<Route path="serie-new" element={<SerieProductsNew />} />
						<Route path="serie-edit/:id" element={<SerieProductsEdit />} />
						<Route path="rental-list" element={<RentalList />} />
						<Route path="serie-rental-edit/:id" element={<SerieRentalEdit />} />
					</Route>
					<Route path="advertisements/*">
						<Route path="list" element={<AdvertisersList />} />
						<Route path="sponsor-list" element={<AdvertisersSponsorLists />} />
						<Route path="new" element={<AdvertisersNew />} />
						<Route path="range-list" element={<BannersPublicityRangeList />} />
						<Route path="range-configuration/new" element={<NewVisibilityConfig />} />
						<Route path="edit/:id" element={<AdvertisersEdit />} />
						<Route path="visibility/:id/:is_video/" element={<AddVisibilityList />} />
						<Route path="range-configuration/edit/:id/:content_id" element={<VisibilityEdit />} />
					</Route>
					<Route path="stickers/*">
						<Route path="series-stickers-list" element={<SeriesStickersList />} />
						<Route path="global-stickers-list" element={<GlobalStickersList />} />
						<Route path="new" element={<StickersNew />} />
						<Route path="edit/:id" element={<StickersEdit />} />
					</Route>
					<Route path="multimedia/*">
						<Route path="list" element={<MultimediaList />} />
						<Route path="video-list" element={<VideoList />} />
						<Route path="video-publicity-list" element={<VideoPublicityList />} />
						<Route path="video-exclusive-room-list" element={<VideoExclusiveRoomList />} />
						<Route path="video-short-list" element={<VideoShortList />} />
						<Route path="lives-streams/list" element={<LiveList />} />
						<Route path="lives-streams/edit/:id" element={<LiveEdit />} />
					</Route>
					<Route path="short_videos/*">
						<Route path="list" element={<ShortsList />} />
						<Route path="new" element={<ShortsNew />} />
						<Route path="edit/:id" element={<ShortEdit />} />
					</Route>
					<Route path="users/*">
						<Route path="admins" element={<UsersAdmin />} />
						<Route path="new" element={<UsersNew />} />
						<Route path="odoo" element={<OdooManagment />} />
						<Route path="internal/new" element={<UsersInternalNew />} />
						<Route path="global-notifications" element={<GlobalNotifications />} />
						<Route path="internals/list" element={<InternalCustomerServiceList />} />
					</Route>
					<Route path="statistics/*">
						<Route path="series" element={<StatisticsSeries />} />
						<Route path="subscriptions" element={<StatisticsSubscriptions />} />
					</Route>
					<Route path="versions/*">
						<Route path="list" element={<ListVersions />} />
					</Route>
				</Route>
				{/* End of Admin Routes */}
				{/* Admin, Marketing and Corporate Routes */}
				<Route element={<ProtectedRoute requiredRole={["admin", "marketing", "corporate"]} />}>
					<Route path="settings/*">
						<Route path="programmed-tasks" element={<ProgrammedTasksList />} />
					</Route>
				</Route>
				{/* EndAdmin, Marketing and Corporate Routes */}
				{/* Admin and Corporate Routes */}
				<Route element={<ProtectedRoute requiredRole={["admin", "master_editor", "customer_service", "marketing", "corporate"]} />}>
					<Route path="users/list" element={<UsersList />} />
					<Route path="users/edit/:id" element={<UserEdit />} />
					<Route path="users/edit/internal/:id" element={<UserInteralEdit />} />
					<Route path="comments/*">
						<Route path="list" element={<CommentsList />} />
						<Route path="reported" element={<CommentsReported />} />
						<Route path="deleted" element={<CommentsDeleted />} />
					</Route>
					<Route path="customer-service/*">
						<Route path="contact" element={<Contacts />} />
					</Route>
				</Route>
				{/* End Admin and Corporate Routes */}
				{/* Marketing and Corporate Routes */}
				<Route element={<ProtectedRoute requiredRole={["marketing", "corporate"]} />}>
					<Route path="marketing-notifications" element={<MarketingNotifications />} />
					<Route path="marketing-custom-notifications" element={<CustomMarketingNotifications />} />
				</Route>
				{/* End Marketing and Corporate Routes */}

				<Route element={<ProtectedRoute requiredRole={["corporate", "customer_service", "admin"]} />}>
					<Route path="/customer-service/user-chat" element={<UserChat />} />
				</Route>

				{/* Corporate Only Routes */}
				<Route element={<ProtectedRoute requiredRole={["corporate"]} />}>
					<Route path="corporate/*">
						<Route path="marketing" element={<MarketingDashboard />} />
						<Route path="influencer" element={<InfluencerList />} />
						<Route path="partner" element={<PartnerList />} />
						<Route path="partner/:name/:id" element={<PartnerDetail />} />
						<Route path="partner/:name/:id/reports" element={<PartnerReports />} />
						<Route path="sponsor" element={<SponsorList />} />
						<Route path="sponsor/:name/:id" element={<SponsorDetail />} />
						<Route path="influencer/:name/:id" element={<InfluencerDetail />} />
						<Route path="purchases" element={<PurchasesDetail />} />
						<Route path="ranked-series" element={<RankedSeries />} />
						<Route path="ranked-contents" element={<RankedContents />} />
						<Route path="series-detail/:id/:name" element={<SeriesDetail />} />
						<Route path="content-detail/:serie/:serie_id/:name/:id" element={<ContentDetail />} />
						<Route path="users" element={<UsersInfoStatistics />} />
						<Route path="marketing-notifications" element={<MarketingNotifications />} />
						<Route path="customer-service/contact" element={<Contacts />} />
						<Route path="customer-service/comments/list" element={<CommentsList />} />
						<Route path="customer-service/users/list" element={<UsersList />} />
						<Route path="performance" element={<Performance />} />
						<Route path="expenses" element={<Expenses />} />
					</Route>
				</Route>
				{/* End Of Corporate Only Routes */}
				{/* Partner Only Routes */}
				<Route element={<ProtectedRoute requiredRole={["partner"]} />}>
					<Route path="partner/*">
						<Route path="contents/*">
							<Route index element={<SeriesContentPartner />} />
							<Route path=":serieId/:id" element={<ContentStatistics />} />
						</Route>
						<Route path="series/*">
							<Route index element={<SeriesPartner />} />
							<Route path=":id" element={<SerieStatistics />} />
						</Route>
						<Route path="products/*">
							<Route index element={<ProductsPartner />} />
							<Route path=":id" element={<ProductStatistics disableRedirect />} />
						</Route>
						<Route path="reports" element={<PartnerReports />} />
					</Route>
				</Route>
				{/* End Of Partner Only Routes */}
				{/* Influencer Only Routes */}
				<Route element={<ProtectedRoute requiredRole={["influencer"]} />}>
					<Route path="influencer/*">
						<Route path="contents/*">
							<Route index element={<SeriesContentInfluencer />} />
							<Route path=":serieId/:id" element={<InfluencerContentStatistics />} />
						</Route>
						<Route path="series/*">
							<Route index element={<SeriesInfluencer />} />
							<Route path=":id" element={<InfluencerSerieStatistics />} />
						</Route>
					</Route>
				</Route>
				{/* End Of Influencer Only Routes */}
			</Route>
		</Route>,
	),
);

function App() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			if (!user) {
				dispatch(appStatusActions.setAppLoading(false));
				router.navigate("/sign-in");
			} else {
				dispatch(userAuthActions.initGetUserData(auth.currentUser!)).then(() => {
					dispatch(appStatusActions.setAppLoading(false));
				});
			}
		});

		return () => {
			unsubscribe();
		};
	}, [dispatch]);

	return <RouterProvider router={router} />;
}

export default App;
