import {format} from "date-fns";
import {Button} from "../../../../../../components/primitives/Button";
import {CancelCircleFilled, CheckCircleFilled, Pencil, Spinner, Close} from "../../../../../../components/primitives/icons";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../../../../components/primitives/Table";
import Tooltip from "../../../../../../components/primitives/ToolTip-toDeprecate";
import {Slots, UserSubscription} from "../../../../../../data-access/subscriptions/subscriptions";
import {useEffect, useMemo, useState} from "react";
import {auth} from "../../../../../../firebase";
import {fetchDbUsers} from "../../../../../../data-access/fetch-db-users";
import {dbUser} from "../../../../../../_store/features/user-db/user-db-slice";
import toast from "react-hot-toast";
import {useAppDispatch} from "../../../../../../_store/hooks";
import {subscriptionActions} from "../../../../../../_store/features/subscriptions/subscriptions-slice";

export interface SubscriptionsTableProps {
	data?: UserSubscription[];
	isLoading?: boolean;
	onSelectRow?: (row: UserSubscription) => void;
	owner: boolean;
}

export default function SubscriptionsTable({data, isLoading, owner, onSelectRow}: SubscriptionsTableProps) {
	const slot = useMemo(() => data?.[0]?.slots?.[0], [data]);
	const [subscriber, setSubscriber] = useState<dbUser | null>(null);
	const [isSubscriberLoading, setIsLoading] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchUsersById = async (id: string) => {
			try {
				setIsLoading(true);
				const token = await auth.currentUser?.getIdToken();
				if (!token) return;

				const response = await fetchDbUsers({idToken: token, searchby: "id", searchvalue: id, page: 0, admin: false});
				if (!response.data) return;

				setSubscriber(response.data.results[0]);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		if (slot?.subscriber_id) fetchUsersById(slot?.subscriber_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slot?.subscriber_id]);

	const handleClearBuddy = async () => {
		if (!slot) {
			return toast.error("No se ha seleccionado un cupo");
		}

		if (window.confirm("¿Estás seguro de eliminar el usuario del cupo?")) {
			const updatedSlot = {...slot, buddy_id: null, buddy_name: "", buddy_email: ""} as Slots;

			const thunk = await dispatch(subscriptionActions.manageAdminBuddyPlan(updatedSlot));

			if (thunk.meta.requestStatus === "rejected") {
				toast.error("Error al eliminar el usuario del cupo", {position: "top-right"});
				return;
			}

			toast.success("Usuario eliminado del cupo correctamente", {position: "top-right"});
		}
	};

	return (
		<Table className="h-40">
			<TableHeader>
				<TableRow>
					<TableHead>Activa</TableHead>
					<TableHead>Suscripción</TableHead>
					<TableHead>Fecha de terminación</TableHead>
					{owner ? (
						<>
							<TableHead>Autorrenovación</TableHead>
							<TableHead>Plataforma</TableHead>
							<TableHead>Controles</TableHead>
						</>
					) : (
						<>
							<TableHead>Plan familiar</TableHead>
							<TableHead>Controles</TableHead>
						</>
					)}
				</TableRow>
			</TableHeader>
			<TableBody>
				{isLoading ? (
					<TableCell colSpan={6}>
						<Spinner className="mx-auto my-5 h-6 w-6 animate-spin" />
					</TableCell>
				) : !data?.length ? (
					<TableCell colSpan={6}>
						<span className="text-lg font-semibold text-amber-600">Usuario sin suscripción</span>
					</TableCell>
				) : (
					data?.map((subscription, idx) => {
						const unformattedDate = new Date(subscription.termination_date);
						const date = format(unformattedDate, "yyyy/MM/dd");

						return (
							<TableRow className="odd:bg-accent/40">
								<TableCell>
									<div className="flex w-full items-center">
										<span
											className={
												"relative inline-block px-3 font-semibold leading-tight" +
												(!subscription.active ? " text-red-700" : " text-green-700")
											}
										>
											<Tooltip
												text={!subscription.active ? "Desactivada" : "Activada"}
												placement={idx === 0 ? "bottom" : "top"}
											>
												{!subscription.active ? <CancelCircleFilled /> : <CheckCircleFilled />}
											</Tooltip>
										</span>
									</div>
								</TableCell>
								<TableCell>{subscription.name}</TableCell>
								<TableCell>{date || "Sin definir"}</TableCell>
								{owner ? (
									<>
										<TableCell>
											<div className="flex w-full items-center">
												<span
													className={
														"relative inline-block px-3 font-semibold leading-tight" +
														(!subscription.subscription_renewal ? " text-red-700" : " text-green-700")
													}
												>
													<Tooltip
														text={subscription.subscription_renewal ? "Autorrenovación" : "Sin Autorrenovación"}
														placement={idx === 0 ? "bottom" : "top"}
													>
														{!subscription.subscription_renewal ? <CancelCircleFilled /> : <CheckCircleFilled />}
													</Tooltip>
												</span>
											</div>
										</TableCell>
										<TableCell className="capitalize">
											{subscription.prospay_subscriptionid ? "Prospay" : subscription.last_payment_platform}
										</TableCell>
										<TableCell>
											<Button size={"sm"} variant={"outline"} onClick={() => onSelectRow?.(subscription)} disabled={false}>
												<Pencil className="h-4 w-4" />
											</Button>
										</TableCell>
									</>
								) : (
									<>
										<TableCell>
											{isSubscriberLoading ? (
												<Spinner className="mx-auto my-5 h-6 w-6 animate-spin" />
											) : (
												<>
													<p>{subscriber?.email || "(no email)"}</p>
													{!!subscriber?.id && <p>{subscriber?.id}</p>}
												</>
											)}
										</TableCell>
										<TableCell>
											<Button size={"sm"} variant={"outline"} onClick={handleClearBuddy} disabled={false}>
												<Close className="h-4 w-4" />
											</Button>
										</TableCell>
									</>
								)}
							</TableRow>
						);
					})
				)}
			</TableBody>
		</Table>
	);
}
