import {Tab} from "@headlessui/react";
import {useEffect, useMemo} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {cn} from "../../../../utils/classNames";
import {useAppDispatch, useAppSelector} from "../../../_store/hooks";
import UserConfiguration from "./Tabs/Configuration";
import Memberships from "./Tabs/Memberships";
import PasswordManagement from "./Tabs/PasswordManagement";
import UserData from "./Tabs/UserData";
import UserNotifications from "./Tabs/UserNotifications";
import useCurrentUserRole from "../../../components/hooks/useCurrentUserRole";
import useUserRole from "../../../components/hooks/useUserRole";
import useUserVinculated from "../../../components/hooks/useUserVinculated";
import Subscriptions from "./Tabs/Subscriptions/Subscriptions";
import {auth} from "../../../firebase";
import {fetchDbUsersData} from "../../../_store/features/user-db/user-db-actions";
import UserChatTab from "./Tabs/UserChat/List";
import Rentals from "./Tabs/Rentals";

const tabsCls = ({selected}: {selected: boolean}) =>
	cn(
		"w-full py-4 text-xs font-medium uppercase leading-tight ",
		"focus:outline-none ",
		selected ? "border-b-2 border-b-black bg-white text-black shadow" : "text-neutral-500",
	);

export default function UserEdit() {
	let {id} = useParams();
	const [searchParams] = useSearchParams();
	const currentUserRole = useCurrentUserRole();
	const dispatch = useAppDispatch();
	const user = useAppSelector(state => state.dbuser.results.find(el => el.id === id));
	const selectedUserRole = useUserRole(user);
	const isVinculated = useUserVinculated(id);
	const navigate = useNavigate();

	useEffect(() => {
		if (!user && id) {
			auth.currentUser?.getIdToken().then(idToken => {
				dispatch(
					fetchDbUsersData({
						idToken,
						page: 0,
						page_size: 1,
						searchvalue: id,
						searchby: "id",
						admin: false,
						role: undefined,
					}),
				);
			});
		}
	}, [dispatch, id, navigate, selectedUserRole, user]);

	const isUserChatHidden = useMemo(() => {
		switch (currentUserRole) {
			case "admin":
			case "corporate":
			case "customer_service":
				return false;
			default:
				return true;
		}
	}, [currentUserRole]);

	const tabIndex = useMemo(() => {
		const tabIndex = searchParams.get("tab");
		if (tabIndex) return parseInt(tabIndex);
	}, [searchParams]);

	const showCofigurationTab = useMemo(() => {
		if (!currentUserRole || !selectedUserRole) return false;
		const isValidAdminUser = ["admin", "master_editor", "marketing"].includes(currentUserRole);
		const isValidRequestedUser = ["partner", "sponsor", "influencer"].includes(selectedUserRole);
		return isValidAdminUser && isValidRequestedUser;
	}, [currentUserRole, selectedUserRole]);

	if (!id) {
		return <div>No Params Id</div>;
	}

	return (
		<Tab.Group defaultIndex={tabIndex}>
			<Tab.List className="flex items-center justify-center space-x-1 border-b border-gray-200">
				<Tab className={tabsCls}>Editar Usuario</Tab>
				<Tab className={tabsCls}>Manejo de Contraseña</Tab>
				<Tab className={tabsCls} hidden={!!selectedUserRole || currentUserRole === "marketing" || isVinculated}>
					Suscripciones
				</Tab>
				<Tab className={tabsCls} hidden={!!selectedUserRole || currentUserRole === "marketing" || isVinculated}>
					Membresías
				</Tab>
				<Tab className={tabsCls} hidden={!!selectedUserRole || currentUserRole === "marketing" || isVinculated}>
					Rentas
				</Tab>
				<Tab className={tabsCls} hidden={selectedUserRole === "corporate" || isVinculated}>
					Notificaciones
				</Tab>
				{!showCofigurationTab && (
					<Tab className={tabsCls} hidden={isUserChatHidden}>
						Chat de Usuario
					</Tab>
				)}
				{showCofigurationTab && <Tab className={tabsCls}>Configuración</Tab>}
			</Tab.List>
			<Tab.Panels>
				<Tab.Panel>
					<UserData />
				</Tab.Panel>
				<Tab.Panel>
					<PasswordManagement />
				</Tab.Panel>
				<Tab.Panel>
					<Subscriptions />
				</Tab.Panel>
				<Tab.Panel>
					<Memberships />
				</Tab.Panel>
				<Tab.Panel>
					<Rentals />
				</Tab.Panel>
				<Tab.Panel>
					<UserNotifications />
				</Tab.Panel>
				{!showCofigurationTab && (
					<Tab.Panel>
						<UserChatTab />
					</Tab.Panel>
				)}
				<Tab.Panel>
					<UserConfiguration />
				</Tab.Panel>
			</Tab.Panels>
		</Tab.Group>
	);
}
