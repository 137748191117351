import {Comment, User} from "../../../../primitives/icons";
import {Settings} from "../../../../primitives/icons/Settings";
import NavGroup from "../../subcomponents/NavGroup";
import NavLink from "../../subcomponents/NavLink";

export function MarketingNavigationSection() {
	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavGroup title="Notificaciones" route="/notifications" icon={<Comment />}>
				<NavLink to="/marketing-notifications" label="Contenidos" />
				<NavLink to="/marketing-custom-notifications" label="Personalizadas" />
			</NavGroup>
			<NavGroup title="Usuarios" route="/users" icon={<User />}>
				<NavLink to="/users/admins" label="Influencers" />
				<NavLink to="/users/odoo" label="Odoo" />
			</NavGroup>
			<NavGroup title="Ajustes" route="/settings" icon={<Settings height={20} />}>
				<NavLink to="/settings/programmed-tasks" label="Listar Tareas" />
			</NavGroup>
		</div>
	);
}
