import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {seriesActions} from "../../../../../_store/features/series/series-slice";
import {associatesActions} from "../../../../../_store/features/associates/associates-slice";
import {Pencil, PlusSmall, Spinner} from "../../../../../components/primitives/icons";
import {Button} from "../../../../../components/primitives/Button";
import {Rental} from "../../../../../data-access/series/user-rentals";
import {createColumnHelper} from "@tanstack/react-table";
import {DataTable} from "../../../../../components/blocks/DataTable";
import {DataTableColumnHeader} from "../../../../../components/primitives/DataTable";
import {Badge} from "../../../../../components/primitives/Badge";
import {formattedDayAndHour} from "../../../../../../utils/formattedDayAndHour";
import EditRentalSheet from "./subcomponents/EditRentalSheet";
import Select from "react-select";
import {selectGlobalStyles} from "../../../../../../utils/selectGlobalStyles";
import {rentalActions} from "../../../../../_store/features/rentals/slice";
import {StatusEnum} from "../../../../../_store/features/rentals/types";
import {getUnselectedRentals} from "../../../../../_store/features/rentals/selector";
import CreateRentalSheet from "./subcomponents/CreateRentalSheet";
import toast from "react-hot-toast";

const columnHelper = createColumnHelper<Rental>();

const Rentals = () => {
	const {id} = useParams();
	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const listAvailableRentals = useAppSelector(getUnselectedRentals);
	const [serieRentalId, setSerieRentalId] = useState("");
	const [openCreateRentalSheet, setOpenCreateRentalSheet] = useState(false);
	const {rentals: currentUserRentalList, status, currentRental} = useAppSelector(state => state.rentals);
	const dispatch = useAppDispatch();

	const handleEditRental = useCallback((rental: Rental) => {
		dispatch(rentalActions.selectCurrentRental(rental));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnEditRentalClose = useCallback((open: boolean) => {
		if (!open) dispatch(rentalActions.clearCurrentRental());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnCreateRentalClose = useCallback((open: boolean) => {
		if (!open) {
			setOpenCreateRentalSheet(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnOpenCreateRentalSheet = useCallback(() => {
		if (serieRentalId === "") {
			return toast.error("Debe seleccionar un producto primero.", {position: "top-right"});
		}

		setOpenCreateRentalSheet(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serieRentalId]);

	const columns = useMemo(() => {
		let tableCellDefaultWidth = (tableRef?.getBoundingClientRect().width ?? 0) / 7.01;

		return [
			columnHelper.accessor("series_title", {
				id: "producto",
				header: ({column}) => <DataTableColumnHeader title="Producto" column={column} />,
				cell: info => <div className="flex w-full text-left">{info.getValue()}</div>,
				size: tableCellDefaultWidth,
				enableResizing: false,
				enableSorting: false,
			}),
			columnHelper.accessor("rental_name", {
				id: "Nombre de la renta",
				header: ({column}) => <DataTableColumnHeader title="Tipo de renta" column={column} />,
				cell: info => <div className="flex w-full text-left">{info.getValue()}</div>,
				size: tableCellDefaultWidth,
				enableResizing: false,
				enableSorting: false,
			}),
			columnHelper.accessor("created_at", {
				id: "Creado",
				header: ({column}) => <DataTableColumnHeader title="Creado" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: tableCellDefaultWidth,
			}),
			columnHelper.accessor("first_use_date", {
				id: "Primer Uso",
				header: ({column}) => <DataTableColumnHeader title="Primer Uso" column={column} />,
				cell: info => {
					if (!info.row.original.first_use_date) {
						return (
							<div className="flex flex-1 flex-col items-center justify-start">
								<span className="overflow-hidden text-ellipsis text-left">No ha sido usado aún</span>
							</div>
						);
					}
					const formattedTime = formattedDayAndHour(info.row.original.first_use_date);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: tableCellDefaultWidth,
			}),
			columnHelper.accessor("termination_date", {
				id: "Fecha de terminación",
				header: ({column}) => <DataTableColumnHeader title="Fecha de terminación" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.termination_date);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: tableCellDefaultWidth,
			}),
			columnHelper.accessor("active", {
				id: "Activo",
				header: ({column}) => <DataTableColumnHeader title="Activo" column={column} />,
				cell: info => (
					<div className="flex w-full">
						{info.getValue() ? (
							<Badge variant={"success"} className="ml-2 flex max-h-[20px] text-left">
								Activo
							</Badge>
						) : (
							<Badge variant={"destructive"} className="ml-2 flex max-h-[20px] text-left">
								Inactivo
							</Badge>
						)}
					</div>
				),
				size: tableCellDefaultWidth,
			}),
			columnHelper.display({
				header: "Controles",
				cell: info => (
					<div className="justify-left flex w-full gap-2">
						<Button size={"sm"} variant={"outline"} onClick={() => handleEditRental(info.row.original)}>
							<Pencil className="h-4 w-4" />
						</Button>
					</div>
				),
				size: tableCellDefaultWidth,
				enableResizing: false,
			}),
		];
	}, [handleEditRental, tableRef]);

	useEffect(() => {
		if (!id) return;

		dispatch(
			seriesActions.getSeriesList({
				params: {page_size: 9999},
			}),
		);
		dispatch(associatesActions.getUserAssociatesSeries({page_size: 99999}));

		dispatch(rentalActions.loadRentalsByUserId(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (status === StatusEnum.LOADING) {
			setSerieRentalId("");
		}
	}, [status]);

	const isLoading = status === StatusEnum.LOADING;
	const isRentalSelected = !!currentRental;

	return (
		<>
			<div className="px-4 py-5 sm:px-6">
				<h2 className="text-base font-semibold leading-6 text-gray-900">Detalles de Rentas</h2>
				<p className="mt-1 max-w-2xl text-sm text-gray-500">Actualiza las rentas de los usuarios y agrega nuevas.</p>
			</div>

			<div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Añadir nueva renta:</h3>
				<div className="mt-1 items-center text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{isLoading ? (
						<Spinner />
					) : (
						<div className="flex flex-row">
							<Select
								className="basic-multi-select mr-2 w-full rounded"
								options={listAvailableRentals || []}
								styles={selectGlobalStyles}
								placeholder="Agrega una renta"
								onChange={option => {
									setSerieRentalId(option?.value || "");
								}}
							/>
							<Button type="button" variant="outline" onClick={handleOnOpenCreateRentalSheet} className="h-9">
								<PlusSmall />
							</Button>
						</div>
					)}
				</div>
			</div>

			<div className="relative border-t border-gray-200">
				<div className="flex h-full flex-col p-6 pt-4">
					<DataTable
						ref={ref => setTableRef(ref)}
						columns={columns}
						dataset={currentUserRentalList}
						pageCount={1}
						pagination={{pageIndex: 0, pageSize: 1000}}
						loading={isLoading}
						withDynamicPageSize
						rowHeight={53}
						showPagination={false}
					/>
				</div>
				<EditRentalSheet open={isRentalSelected} onOpenChange={handleOnEditRentalClose} key={currentRental?.seriesid} />
				<CreateRentalSheet
					open={openCreateRentalSheet}
					serie_rentalid={serieRentalId}
					key={serieRentalId}
					onOpenChange={handleOnCreateRentalClose}
				/>
			</div>
		</>
	);
};

export default Rentals;
