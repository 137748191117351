import {Button} from "../../../components/primitives/Button";
import {ScrollArea} from "../../../components/primitives/ScrollArea";
import {Sheet, SheetClose, SheetContent, SheetDescription, SheetHeader, SheetProps, SheetTitle} from "../../../components/primitives/Sheet";
import {Textarea} from "../../../components/primitives/Textarea";
import {useAppDispatch} from "../../../_store/hooks";
import {Fragment, useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {FormDataResult, formDbActions} from "../../../_store/features/forms/customer-service-slice";
import {Listbox, Transition} from "@headlessui/react";
import {Chevron} from "../../../components/primitives/icons";
import {EditFormBodyType} from "../../../data-access/fetch-form-data";

interface ReplySheetProps extends SheetProps {
	contactData: FormDataResult;
}

const ESTADOS = [
	{display: "Pendiente", value: 0},
	{display: "Visto", value: 1},
	{display: "Resuelto", value: 2},
];

export default function MessageReplySheet(props: ReplySheetProps) {
	const {contactData, ...rest} = props;

	const [selectedType, setSelectedType] = useState(ESTADOS[0]);
	const [FormData, setFormData] = useState<EditFormBodyType>({
		id: "",
		name: "",
		email: "",
		phone: "",
		message: "",
		sponsor: false,
		customer_service_note: "",
		status: 0,
	});
	const dispatch = useAppDispatch();

	useEffect(() => {
		const defaultValue = contactData.status;
		const defaultOption = ESTADOS.find(option => option.value === defaultValue);
		defaultOption && setSelectedType(defaultOption);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactData.status]);

	useEffect(() => {
		const initialData: EditFormBodyType = {
			id: contactData.id,
			name: contactData.name !== null ? contactData.name : "",
			email: contactData.email,
			phone: contactData.phone,
			message: contactData.message,
			sponsor: contactData.sponsor,
			customer_service_note: contactData.customer_service_note !== null ? contactData.customer_service_note : "",
			status: contactData.status,
		};
		setFormData(initialData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactData.id]);

	const handleInsertReply = async (data: EditFormBodyType) => {
		const res = await dispatch(formDbActions.updateFormData(data));

		if (res.meta.requestStatus === "fulfilled") {
			toast.success("Se actualizaron los datos correctamente.", {position: "bottom-left"});
		}
		if (res.meta.requestStatus === "rejected") {
			toast.error("Error al actualizar la información.", {position: "bottom-left"});
		}
	};

	const handleChangeData = (value: any, key: string) => {
		setFormData({...FormData, [key]: value});
	};

	return (
		<Sheet {...rest}>
			<SheetContent className="flex max-h-screen w-[400px] flex-col sm:w-[540px] sm:max-w-max">
				<SheetHeader>
					<SheetTitle>Detalles del mensaje</SheetTitle>
					<SheetDescription>Deja una nota sobre la respuesta que se le dió a este usuario.</SheetDescription>
				</SheetHeader>
				<div className="flex flex-row justify-between">
					<div className="flex flex-row">
						<span className="justify-center self-center font-medium">Usuario:</span>
						<div className="flex flex-col ">
							<span className="ml-2 text-sm">{contactData.name !== "" ? contactData.name : "Anónimo"}</span>
							<span className="ml-2 text-xs">{contactData.email}</span>
						</div>
					</div>

					<div className="flex flex-row">
						<span className="mr-2 justify-center self-center font-medium">Estado:</span>
						<Listbox
							value={selectedType}
							onChange={value => {
								setSelectedType(value);
								handleChangeData(value.value, "status");
							}}
						>
							<div className="relative">
								<Listbox.Button className="relative h-full w-full cursor-default rounded-sm bg-neutral-900 py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
									<span className="block truncate">{selectedType.display}</span>
									<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
										<Chevron className="h-5 w-5 rotate-180 text-gray-400" aria-hidden="true" />
									</span>
								</Listbox.Button>
								<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
									<Listbox.Options className="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
										{ESTADOS.map((option, personIdx) => (
											<Listbox.Option
												key={personIdx}
												className="relative cursor-default select-none px-4 py-2 hover:bg-neutral-300"
												value={option}
											>
												{({selected}) => (
													<span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
														{option.display}
													</span>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
				</div>

				<ScrollArea className="h-full w-full rounded-md border p-4 text-sm" contentEditable={false}>
					{contactData.audit_created.slice(8, 10) +
						"-" +
						contactData.audit_created.slice(5, 7) +
						"-" +
						contactData.audit_created.slice(0, 4)}
					<br />
					{contactData.message}
				</ScrollArea>

				<div>
					<Textarea
						placeholder={"Insertar nota del contacto..."}
						rows={5}
						onChange={e => handleChangeData(e.target.value, "customer_service_note")}
						defaultValue={contactData.customer_service_note !== null ? contactData.customer_service_note : ""}
					/>
				</div>
				<SheetClose asChild>
					<Button
						type="button"
						className="mt-4"
						onClick={() => {
							handleInsertReply(FormData);
						}}
					>
						Guardar
					</Button>
				</SheetClose>
			</SheetContent>
		</Sheet>
	);
}
