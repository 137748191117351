import Select from "react-select";
import {useEffect, useMemo, useState} from "react";
import {selectStyles} from "../../../../../utils/SelectStyles";
import {GoogleMap, InfoWindowF, MarkerF, useLoadScript} from "@react-google-maps/api";
import Loader from "./Loader";
import {GetSponsorWorldViewsData} from "../../../../data-access/dashboard/types";
import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {DateRange} from "react-day-picker";
import {formatISO9075} from "date-fns";
import useCurrentUserRole from "../../../hooks/useCurrentUserRole";
import {useParams} from "react-router-dom";
import {getRadio} from "../../../../../utils/getMapMarkerRadio";

interface SelectOption {
	label: string;
	value: string;
}

interface SponsorGoogleWorldChartProps {
	adsList?: SelectOption[];
	dates?: DateRange;
}

export function SponsorGoogleWorldChart({adsList, dates}: SponsorGoogleWorldChartProps) {
	const {id: selectedSponsorId} = useParams<{id: string}>();
	const dispatch = useAppDispatch();
	const userRole = useCurrentUserRole();
	const sponsor = useAppSelector(({user}) => user.userInfo);
	const sponsorViewsDetail = useDashboardByNameQuery("sponsorWorldViews");
	const [selectedAd, setSelectedAd] = useState(adsList ? adsList[0] : null);

	const [activeMarker, setActiveMarker] = useState<GetSponsorWorldViewsData | null>(null);
	const {isLoaded} = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
	});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const center = useMemo(() => ({lat: 0, lng: 0}), [selectedAd]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const defaultZoom = useMemo(() => 3, [selectedAd]);
	useEffect(() => {
		if (!adsList) {
			return;
		}
		dispatch(
			dashboardActions.getSponsorWorldViewsAction({
				sponsor_id: userRole === "sponsor" ? sponsor?.id : selectedSponsorId,
				publicity_id: selectedAd?.value || undefined,
				initial_date: dates ? formatISO9075(dates?.from!) : undefined,
				final_date: dates ? formatISO9075(dates?.to!) : undefined,
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAd, dates, dispatch, selectedSponsorId]);

	return (
		<div className="flex flex-1 flex-col rounded-lg border bg-card">
			<div className="flex flex-row items-center justify-between">
				<h3 className="p-8 text-left text-lg font-semibold leading-none tracking-tight">Vistas por Países.</h3>
				<div className="mb-3 w-auto basis-1/3 pr-8">
					<Select
						className="mt-1 w-full rounded ring-1"
						styles={selectStyles}
						options={adsList}
						value={selectedAd}
						onChange={selectedOption => {
							setSelectedAd(selectedOption);
						}}
					/>
				</div>
			</div>
			<div className="flex items-center justify-between border-b border-border" />

			{!isLoaded ? (
				<Loader title="" />
			) : (
				<GoogleMap
					center={center}
					zoom={defaultZoom}
					mapContainerStyle={{height: 700, width: "100%"}}
					options={{
						mapTypeControl: false,
						streetViewControl: false,
						zoomControlOptions: {
							position: 9,
						},
						minZoom: 3,
						maxZoom: 5,
					}}
				>
					{sponsorViewsDetail.data?.views_by_location?.map((item, index: number) => {
						return (
							<MarkerF
								key={index}
								position={{lat: Number(item.latitude), lng: Number(item.longitude)}}
								icon={{
									path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
									fillColor: "#FF0000",
									fillOpacity: 0.4,
									strokeWeight: 0,
									scale: getRadio(item.view_count, 1500),
								}}
								onClick={() => setActiveMarker(item)}
							>
								{activeMarker && activeMarker === item ? (
									<InfoWindowF onCloseClick={() => setActiveMarker(null)}>
										<div className="my-2 flex flex-col gap-1">
											<p className="text-start ">
												<b>Region:</b> {activeMarker.city}
											</p>
											<p className="text-start">
												<b>Views:</b> {activeMarker.view_count}
											</p>
										</div>
									</InfoWindowF>
								) : null}
							</MarkerF>
						);
					})}
				</GoogleMap>
			)}
		</div>
	);
}
